import SEO from "components/seo";
import Layout from "components/layout";
import React from "react";
import Melbourne from "components/locationIndexPages/melbourne";
function AtlantaLocation() {
  const title = "Melbourne Translation Services Agency- Tomedes";
  const description =
    "Are you in Melbourne and in need of high-quality translation services? Tomedes provides translation services in over 120 languages and 950+ language pairs. 1-Year Guarantee | Fast Delivery | 24/7 Availability";
  const keywords = "Translation Services in Melbourne";
  return (
    <Layout>
      <SEO
        title={title}
        description={description}
        keywords={keywords}
        slug="/locations/melbourne-translation-services"
      />
      <Melbourne />
    </Layout>
  );
}

export default AtlantaLocation;
